<script setup lang="ts">
import {type HTMLAttributes, computed} from 'vue'
import {TabsTrigger, type TabsTriggerProps, useForwardProps} from 'radix-vue'
import {cn} from '@/lib/utils'

const props = defineProps<TabsTriggerProps & { class?: HTMLAttributes['class'] } & { arrows?: boolean }>()

const delegatedProps = computed(() => {
  const {class: _, ...delegated} = props

  return delegated
})

const forwardedProps = useForwardProps(delegatedProps)
</script>

<template>
  <TabsTrigger
      v-bind="forwardedProps"
      :class="cn(
        {'has_arrows': props.arrows},
      'inline-flex items-center justify-center whitespace-nowrap rounded-sm px-3 py-1.5 text-sm font-medium ring-offset-white transition-all focus-visible:outline-none focus-visible:ring-none disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-white data-[state=active]:text-neutral-950',
      props.class,
    )"
  >
    <slot />
    <div v-if="props.arrows" class="tab_arrow w-5 h-5 flex items-center justify-center rounded-full bg-white shadow-sm ltr:ml-auto rtl:mr-auto">
        <Icon name="ri:arrow-right-s-line" size="15px" class="rtl:rotate-180" />
    </div>
  </TabsTrigger>
</template>

<style scoped lang="scss">
/* .arrows {
  padding-right: 35px;
  border-radius: 8px;
  justify-content: unset;
  font-size: 14px;
  font-weight: 500;
  
  &[data-state="active"] {
    color: rgba(10, 13, 20, 1);
    background: rgba(246, 248, 250, 1);
    position: relative;
    &::after {
      content: "";
      position: absolute;
      right: 5px;
      overflow: hidden;
      top: 50%;
      transform: translateY(-50%);
      background-color: #ffffff;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxZW0iIGhlaWdodD0iMWVtIiB2aWV3Qm94PSIwIDAgMjQgMjQiPjxwYXRoIGZpbGw9ImN1cnJlbnRDb2xvciIgZD0ibTEzLjE3MiAxMmwtNC45NS00Ljk1bDEuNDE0LTEuNDEzTDE2IDEybC02LjM2NCA2LjM2NGwtMS40MTQtMS40MTV6Ii8+PC9zdmc+');
      background-size: 20px 20px;
      background-repeat: no-repeat;
      background-position: center center;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      z-index: 1;
    }
  }
} */

</style>
